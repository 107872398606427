<template>
  <div id="app">
    <xfmall></xfmall>
  </div>
</template>

<script>
import GList from './utils/goodsList.js'
import xfmall from './components/xfmall.vue'
export default {
  name: 'App',
  components: {
    xfmall
  },
  data() {
    return {

     }},
  created() {
    this.goodsList = GList;
    console.log('商品数据',this.goodsList)
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="less">

</style>
