<template>
  <div>
    <div class="f-box">
        <div class="flex-row-between-center logo-box">
        <div class="flex-row-start-center">
          <div class="logo-img">
            <img src="https://cdn.freshfans.cn/static/img/xflogo.png" />
          </div>
          <div v-for="(item,idx) in navList" :key="idx">
            <div @click="selectNav(idx)" class="nav-item" :class="'nav-item_'+idx">{{ item.title }}</div>
          </div>
        </div>
        
        <div class="x-szie">联系电话:400-8829292</div>
      </div>
      <div class="nav-box">
        <div class="flex-row-start-center box x-color">
          <div v-for="(item,idx) in bannerList" @click="selectIdx(idx)" :class="{'active': curidx == idx }" class="item" :key="idx">{{ item.title }}</div>
        </div>
        
      </div>
    </div>
    <div v-if="curNav!=0" class="b-box">
      <div class="box">
        <div style="text-align: center;" v-for="(item,idx) in navList[curNav].image" :key="idx">
          <!-- <a v-if="curNav==1" href="mailto:chenjiabao@yiduoyungou.com"> -->
          <div v-if="curNav==1" style="min-height: 400px;">
            <img v-if="!isFormData" @click="isFormData = true" :src="`${imgUrl}${item}.jpg`" />
            <formData v-else @close="setFormData"></formData>
          </div>
          <img v-else :src="`${imgUrl}${item}.jpg`" />
        </div>
        
      </div>
    </div>
    <div v-else class="b-box box">
      <div v-if="curidx < 3" class="banner box">
        <div class="flex-row-start-start">
          <div>
            <img :src="imgUrl+ 'banner_' + (curidx+1) + '.jpg'">
          </div>
          <div class="txt-box">
            <div class="title">{{ bannerList[curidx].title }}</div>
            <div class="msg">{{ bannerList[curidx].msg }}</div>
            <div class="flex-row-start-start">
              <div class="icon-box">
                <div class="icon icon1"></div>
                <div class="txt">全场包邮</div>
              </div>
              <div class="icon-box">
                <div class="icon icon2"></div>
                <div class="txt">7天退换</div>
              </div>
              <div class="icon-box">
                <div class="icon icon3"></div>
                <div class="txt">假一赔十</div>
              </div>
              <div class="icon-box">
                <div class="icon icon4"></div>
                <div class="txt">48小时发货</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商品 -->
      <div class="g-box flex-row-start-start">
        <div v-for="(item,idx) in goodsList[curidx]" class="item" :key="idx">
          <div class="img" @mouseover="showImage(idx)">
            <img :src="`${imgUrl}good/${item.id}.jpg`" />
            <div class="qr-img flex-column-center-center" @mouseout="hideImage()" v-show="activeIndex == idx">
              <img :src="`${imgUrl}qrcode/${item.id}.jpg`" />
              <div class="tt">微信扫我购买</div>
            </div>
          </div>
          <div class="txt">
            <div>{{ item.name }}</div>
            <div class="x-mt10">￥{{ item.num }}</div>
          </div>
        </div>
        <div class="item">
          <div class="img"><img :src="`${imgUrl}qrcode.png`" alt=""></div>
          <div class="txt">
            <div>更多商品，请扫码进入鲜范商城小程序购买</div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="tab-box flex-row-center-center box">
        <div class="item" @click="setTab(idx)" v-for="(item,idx) in tabList" :key="idx">{{ item.title }}</div>
      </div>
    <div class="bt-box">
      <div class="box">
        <div class="btt">Copyright 2018-2024 freshfans.cn鲜范商城 版权所有</div>
        <div>
          <a v-if="isXF" target="_blank" href="https://beian.miit.gov.cn/">粤ICP备18101249号-3</a>
          <a v-if="!isXF" target="_blank" href="https://beian.miit.gov.cn/">粤ICP备18101249号-2</a>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import GList from '../utils/goodsList.js'
import formData from './formData'
export default {
  name: 'xfmall',
  components: {
    formData
  },
  data() {
    return {
      imgUrl: 'https://cdn.freshfans.cn/static/xfmall/',
      curidx: 0,
      activeIndex: null,
      isXF: false,
      curNav: 0,
      isFormData: false,
      navList:[{
        title:'首页',image:null},{
        title:'商家入驻',image:['ruzu']},{
        title:'帮助中心',image:['bangzu1','bangzu2','bangzu3']},{
        title:'关于我们',image:['guayu']},{
        title: '联系我们',image:['lianxi']
      }],
      //入驻鲜范商城、帮助中心、关于我们、服务协议、隐私政策
      tabList:[{
        title: '入驻鲜范商城',
        val: 1,
      },{
        title: '帮助中心',
        val: 2,
      },{
        title: '关于我们',
        val: 3,
      },{
        title: '服务协议',
        val: './pages/xfmall_service_info.html',
      },{
        title: '隐私政策',
        val: './pages/xfmall_privacy.html',
      },],
      bannerList: [{
        title:'限时特价',
        msg:'每日精选好货超低价秒杀，限时限量，全场包邮。'
      },{
        title:'鲜范优选',
        msg:'全场9.9元起，汇聚全网优质折扣商品，限时限量特卖，好货不断。'
      },{
        title:'新品上架',
        msg:'优选全球美食，品类丰富，全网超低价，闪电发货到您家!'
      },{
        title:'休闲食品',
        msg: ''
      },{
        title:'生鲜水果',
        msg: ''
      },{
        title:'日用百货',
        msg: ''
      },{
        title:'粮油副食',
        msg: ''
      },{
        title:'烘焙爆品',
        msg: ''
      },{
        title:'电器数码',
        msg: ''
      }],
     }},
  created() {
    this.goodsList = GList;
    console.log('商品数据',this.goodsList)
  },
  mounted() {
    var domain = document.domain;  
    if(domain.includes('freshfans')){
        this.isXF = true;
      }else{  
        this.isXF = false;
      } 
  },
  methods: {
    setFormData() {
      console.log('dddd',this.isFormData)
      this.isFormData = false
      console.log('dddd2',this.isFormData)
    },
    selectIdx(idx) {
      this.curNav = 0
      this.curidx = idx;
    },
    setTab(idx) {
      let item = this.tabList[idx];
      if(idx < 3) {
        this.curNav = item.val
      }else {
        location.href = item.val
      }
    },
    selectNav(idx) {
      this.curNav = idx;
      if(idx == 0 ) {
        this.curidx = 0;
      }else {
        this.curidx = null;
      }
      
    },
    showImage(index) {
      this.activeIndex = index;
    },
    hideImage() {
      this.activeIndex = null;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.tab-box {
  margin-bottom: 50px;
  .item {
    cursor: pointer;
    margin: 0 10px;
  }
}
.f-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 110px;
  z-index: 11;
  background: #fff;
  .logo-box {
    margin: 0 auto;
    width: 1200px;
    height: 96px;
    overflow: hidden;
    position: relative;
    .logo-img {
      height: 76px;
      margin-right: 50px;
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        height: 100%;
      }
    }
  }
  .nav-box {
    background: #ed445b;
    height: 44px;
    line-height: 44px;
    .item {
      padding: 0 10px;
      cursor: pointer;
      margin: 0 10px;
    }
    .active {
      background: #d53758;
    }
  }
}
.nav-item {
  padding: 0 20px;
  border-right: 1px solid #999;
  cursor: pointer;
}
.nav-item_4 {
  border-right: 0px solid #999;
}
.b-box{
  margin-top: 180px;
  .txt-box {
    margin-top: 20px;
    margin-left: 20px;
    .title {
      font-weight: bold;
    }
    .msg {
      margin-top: 20px;
      color: #999;
    }
  }
  .banner {
    //background: #fff;
  }
  .icon-box {
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    color: #999;
    .icon {
      margin: 0 auto;
      width: 44px;
      height: 44px;
      background: url('https://cdn.freshfans.cn/static/xfmall/extra.png');
    }
    .icon1 {
      background-position: 100% 0%;
    }
    .icon2 {
      background-position: 100% 100%;
    }
    .icon3 {
      background-position: 0% 100%;
    }
  }
}
.g-box {
  flex-wrap: wrap;
  margin: 10px 0;
  background: #fafafa;
  .item{
    width: 270px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    padding: 20px 0;
    background: #fff;
    .img {
      position: relative;
      margin: 0 auto;
      width: 220px;
      height: 220px;
      img {
        width: 100%;
      }
    }
    .qr-img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0,0.7);
      img {
        width: 160px;
        height: 160px;
      }
      .tt {
        position: absolute;
        bottom: 5px;
        font-size: 16px;
        color: #fff;
      }
    }
    .txt {
      font-size: 16;
      padding: 10px;
      color: #666;
    }
  }
}
.bt-box {
  font-size: 16px;
  padding-top:10px;
  height: 100px;
  color: #fafafa;
  background: #3f3e3e;
  .btt {
    margin-bottom: 10px;
  }
  a {
    margin-top: 10px;
    color: #fafafa;
    text-decoration: none;
  }
}
</style>
