<template>
    <div>
        <div class="f-box">
            <div style="font-size: 26px;" class="flex-row-bettwen-center">
                <span class="back" @click="closeBtn">＜返回</span>
                <div style="margin-left:100px">鲜范入驻</div>
            </div>
            <div style="margin: 50px 0;">请提交您入驻的相关信息，合适的我们将与您联系</div>
            <div v-for="(item,idx) in fData" :key="idx" class="item">
                <div class="label"><span class="sp">*</span>{{(idx + 1)}}、{{ item.name }}</div>
                <div>
                    <input v-if="[4,5].includes(idx)" type="file" class="input" />
                    <input v-else class="input" v-model="item.val" />
                </div>
            </div>
            <div class="flex-row-start-center btn">
                <button class="close" @click="closeBtn">取消</button>
                <button class="sub" @click="submit">提交</button>
            </div>
            <div v-if="isload" class="msk flex-row-center-center">
                <div class="box">提交中...</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isload: false,
            fData:[
                {'name':'请输入公司名称',val:null},
                {'name':'请输入联系人',val:null},
                {'name':'请输入联系人手机号',val:null},
                {'name':'请输入联系人身份证',val:null},
                {'name':'请上传营业执照',val:null},
                {'name':'请上传商品注册证',val:null},
                {'name':'请输入产品和报价',val:null},
            ],
        }
    },
    methods: {
        closeBtn() {
            console.log('ff')
            this.$emit('close')
        },
        submit() {
            if(!this.fData[0].val || !this.fData[1].val||!this.fData[2].val || !this.fData[3].val) {
                alert('请先输入在提交~')
                return
            }
            this.isload = true
            setTimeout(()=>{
                this.isload = false
                setTimeout(()=>{
                    alert('提交成功，等待鲜范联系')
                    this.closeBtn()
                },50)
                
            },2000)
            
        }
    },
}

</script>
<style scoped lang="less">
.msk {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.1);
    .box {
        width: 200px;
    }
}
.f-box {
    margin: 10px auto;
    width: 600px;
    text-align: left;
}
.btn {
    margin-top: 20px;
    margin-left: 100px;
    margin-bottom: 50px;
    cursor: pointer;
    button {
        width: 60px;
        height: 34px;
    }
    .sub {
        margin-left: 50px;
    }
}
.back {
    color: #999;
    font-size: 20px;
    cursor: pointer;
}
.item {
    margin-top: 20px;
    .label {
        margin-bottom: 10px;        
        .sp {
            color: red;
            vertical-align: sub;
        }
    }
}
.input {
    width: 500px;
    height: 36px;
}
</style>