import Vue from 'vue';
import App from './App.vue';

import '@/assets/styles/index.css'

import vueSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
    
Vue.use(vueSwiper)

Vue.config.productionTip = false;

Vue.prototype.imgUrl = '://freshfans001.oss-cn-shenzhen.aliyuncs.com'; 

new Vue({
  render: (h) => h(App),
}).$mount('#app');
